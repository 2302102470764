<template>
    <div class="login" style="height:100%">
        <div class="logo"><img src="../assets/login_h12.png" width="300px" /></div>
        <div class="panel">
            <div style="display:flex;width:100%;">
                <img src="../assets/login_h1.png" width="100px" style="padding:20px;margin:0 auto" />
            </div>
            <el-form :model="mainForm" :rules="rules" ref="mainForm" style="padding-right:100px" label-width="100px">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="mainForm.username"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input type="password" v-model="mainForm.password"></el-input>
                </el-form-item>
                <el-form-item  class="flex justify-center">
                    <el-button style="width:80px" type="primary" @click="login()">登录</el-button>
                    <el-button style="width:80px" @click="reset()">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    name: "login",
    data() {
        return {
            mainForm: {
                username: "",
                password: "",
            },
            rules: {
                username: [
                    { required: true, message: "请输入用户名", trigger: "blur" },
                    { min: 3, max: 15, message: "长度在 3 到 15 个字符", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    { min: 3, max: 15, message: "长度在 3 到 15 个字符", trigger: "blur" },
                ],
            },
        };
    },
    methods: {
        //管理员登录
        login() {
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    this.$http({
                        url: "/sys/login",
                        data: {
                            username: this.mainForm.username,
                            password: this.mainForm.password,
                        },
                    }).then((res) => {
                        console.log("login->", res.code, res.data);
                        if (res.code == 1) {
                            sessionStorage.setItem("user_token", res.data.token);
                            sessionStorage.setItem("user_fullName", res.data.fullName);
                            this.$router.push("/index");
                        } else {
                            this.$message({
                                message: res.data.message,
                                type: "error",
                            });
                        }
                    });
                }
            });
        },
        reset() {
            this.$refs.mainForm.resetFields();
            this.$refs.mainForm.clearValidate();
        },
    },
};
</script>
<style>
html,
body {
    height: 100%;
}
body {
    margin: 0;
}
.login {
    background: url("../assets/login_bg.png");
    background-size: cover;
}
.logo {
    text-align: center;
    padding: 150px 0 50px 0;
}
.login h1 {
    margin-top: 100px;
    text-align: center;
    color: white;
    font-size: 40px;
    font-family: Georgia, "Times New Roman", Times, serif;
}
.panel {
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    background: white;
    border-radius: 20px;
    /* padding: 20px 100px 50px 0; */
    padding: 20px auto 50px auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
}
</style>
